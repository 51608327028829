interface AppConfig {
  readonly backendUrl: string;
}

const commonConfig = {};

const developmentConfig: AppConfig = {
  ...commonConfig,
  backendUrl: 'https://apila-test.tietopalvelut.biz',
};

const testConfig: AppConfig = {
  ...commonConfig,
  backendUrl: 'https://apila-test.tietopalvelut.biz',
};

const prodConfig: AppConfig = {
  ...commonConfig,
  backendUrl: 'https://apila.almatalent.fi',
};

const configuration: { [key: string]: AppConfig } = {
  dev: developmentConfig,
  test: testConfig,
  prod: prodConfig,
};

const buildEnvironment = process.env.REACT_APP_BUILD_ENV || 'dev';

const appConfig = configuration[buildEnvironment];

export default appConfig;
