import { AuthContextProperties } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import appConfig from 'appConfig';
import {
  RefreshableApiResponse,
  ContactResponseBody,
  IndividualImportPostBody,
  SiteResponseBody,
  CompanyResponseBody,
  IndividualImportInputBusinessIds,
} from 'api/types';
import { jsonFetcher, jsonPoster } from 'api/util';

export function useCompanyContacts(
  authContextProperties: AuthContextProperties,
  crmObjectId: string,
  itemType: string,
): RefreshableApiResponse<ContactResponseBody> {
  const [data, setData] = useState<ContactResponseBody>({ contacts: [] });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  function refresh() {
    setRefreshKey(refreshKey + 1);
  }

  useEffect(
    function fetchCompanyDecisionMakers() {
      setIsLoading(true);
      const url = createContactUrl(authContextProperties, crmObjectId, itemType);
      jsonFetcher<ContactResponseBody>(url)
        .then((response) => {
          setData(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    },
    [authContextProperties, crmObjectId, refreshKey, itemType],
  );
  return { data, isLoading, refresh };
}

export async function saveContacts(
  authContextProperties: AuthContextProperties,
  crmObjectId: string,
  itemType: string,
  postBody: IndividualImportPostBody,
): Promise<unknown> {
  const url = createContactUrl(authContextProperties, crmObjectId, itemType);
  return jsonPoster(url, postBody);
}

export function useCompanies(
  authContextProperties: AuthContextProperties,
  crmObjectId: string,
): RefreshableApiResponse<CompanyResponseBody> {
  const [data, setData] = useState<CompanyResponseBody>({ membersOfEnterpriseGroup: [], enterpriseGroupName: '' });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  function refresh() {
    setRefreshKey(refreshKey + 1);
  }
  useEffect(
    function fetchCompanies() {
      setIsLoading(true);
      const url = createCompanyUrl(authContextProperties, crmObjectId);
      jsonFetcher<CompanyResponseBody>(url)
        .then((response) => {
          setData(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    },
    [authContextProperties, crmObjectId, refreshKey],
  );
  return { data, isLoading, refresh };
}

export function useSites(
  authContextProperties: AuthContextProperties,
  crmObjectId: string,
): RefreshableApiResponse<SiteResponseBody> {
  const [data, setData] = useState<SiteResponseBody>({ sites: [] });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  function refresh() {
    setRefreshKey(refreshKey + 1);
  }
  useEffect(
    function fetchCompanySites() {
      setIsLoading(true);
      const url = createSiteUrl(authContextProperties, crmObjectId);
      jsonFetcher<SiteResponseBody>(url)
        .then((response) => {
          setData(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    },
    [authContextProperties, crmObjectId, refreshKey],
  );
  return { data, isLoading, refresh };
}

export async function saveSites(
  authContextProperties: AuthContextProperties,
  crmObjectId: string,
  postBody: IndividualImportPostBody,
): Promise<unknown> {
  const url = createSiteUrl(authContextProperties, crmObjectId);
  return jsonPoster(url, postBody);
}

export async function saveCompanies(
  authContextProperties: AuthContextProperties,
  crmObjectId: string,
  postBody: IndividualImportInputBusinessIds,
): Promise<unknown> {
  const url = createCompanyUrl(authContextProperties, crmObjectId);
  return jsonPoster(url, postBody);
}

function createContactUrl(
  { signature, timestamp, app, appId, userId }: AuthContextProperties,
  crmObjectId: string,
  itemType: string,
): string {
  const encodedSignature = encodeURIComponent(signature);
  return `${appConfig.backendUrl}/hubspot/crm-cards/matching/companies/${crmObjectId}/contacts?itemType=${itemType}&sig=${encodedSignature}&timestamp=${timestamp}&app=${app}&appId=${appId}&userId=${userId}`;
}

function createSiteUrl(
  { signature, timestamp, app, appId, userId }: AuthContextProperties,
  crmObjectId: string,
): string {
  const encodedSignature = encodeURIComponent(signature);
  return `${appConfig.backendUrl}/hubspot/crm-cards/matching/companies/${crmObjectId}/sites?sig=${encodedSignature}&timestamp=${timestamp}&app=${app}&appId=${appId}&userId=${userId}`;
}

function createCompanyUrl(
  { signature, timestamp, app, appId, userId }: AuthContextProperties,
  crmObjectId: string,
): string {
  const encodedSignature = encodeURIComponent(signature);
  return `${appConfig.backendUrl}/hubspot/crm-cards/matching/companies/${crmObjectId}/companies?sig=${encodedSignature}&timestamp=${timestamp}&app=${app}&appId=${appId}&userId=${userId}`;
}
